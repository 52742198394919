import React from 'react';
import { Image, Link, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ReactComponent from "../../ReactComponent";
import "./storeList.scss";

class StoreList extends ReactComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };
    }

    render() {
        return (
            this.props.fields &&
                this.props.fields.StoreList && this.props.fields.StoreList.map((storeItem, storeIndex) => (
                    <div className="col-md-3 stores">
                        <a className="dropdown-item" href={storeItem.fields && storeItem.fields.StoreLink.value.href} key={storeIndex}>
                            <img src={storeItem.fields.Brand.fields && storeItem.fields.Brand.fields.StoreImage  && storeItem.fields.Brand.fields.StoreImage.value.src}/>
                        </a>
                    </div>
                ))
        )
    }
}

export default withSitecoreContext()(StoreList);
