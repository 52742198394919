import React from 'react';
import './RatingReviewsFilter.css'
import i18next from 'i18next';

const sortOrder = [
    ["rateHighToLow", "Nota: de mayor a menor"],
    ["rateLowToHigh", "Nota: de menor a mayor"],
    ["dateNewToOld",  "Fecha: de más reciente a más antigua"],
    ["dateOldToNew",  "Fecha: de más antigua a más reciente"]
]

const filters = [
    ["allScores", "Todas las notas"],
    ["5stars", "5 estrellas"],
    ["4stars", "4 estrellas"],
    ["3stars", "3 estrellas"],
    ["2stars", "2 estrellas"],
    ["1star", "1 estrella"],
]

class RatingReviewsFilter extends React.Component {

    state = {
        sortingOrder: sortOrder[0][0],
        scoresFilter: filters[0][0]
    }


    componentDidMount(){ 
        this.handleSorting()
    }

    changeOption = (e, filterType) => {
        const selectedOption = e.currentTarget.value;

        if(filterType === "sortingOrder"){
            this.setState({sortingOrder: selectedOption}, this.handleSorting)
        }
        else if(filterType === "scoresFilter"){
            this.setState({scoresFilter: selectedOption}, this.handleSorting)
        }
        
    }


    handleSorting() {
        const {sortingOrder, scoresFilter} = this.state;
        const data = this.props.comments;
        let newData = this.props.comments;

        // SORT ORDER
        if(sortingOrder === "rateHighToLow"){
            newData = data.sort((a,b)=>{return b.item.rating.numberValue - a.item.rating.numberValue})
        }
        else if (sortingOrder === "rateLowToHigh"){
            newData = data.sort((a,b)=>{return a.item.rating.numberValue - b.item.rating.numberValue})
        }
        else if (sortingOrder === "dateNewToOld"){
            newData = data.sort((a,b) => {return b.item.date.value - a.item.date.value})
        }
        else if (sortingOrder === "dateOldToNew"){
            newData = data.sort((a,b) => {return a.item.date.value - b.item.date.value})
        }

        // SCORE FILTER
        if (scoresFilter === "5stars"){
            newData = data.filter(itm => itm.item.rating.numberValue == 5)
        }
        else if (scoresFilter === "4stars"){
            newData = data.filter(itm => itm.item.rating.numberValue == 4)
        }
        else if (scoresFilter === "3stars"){
            newData = data.filter(itm => itm.item.rating.numberValue == 3)
        }
        else if (scoresFilter === "2stars"){
            newData = data.filter(itm => itm.item.rating.numberValue == 2)
        }
        else if (scoresFilter === "1star"){
            newData = data.filter(itm => itm.item.rating.numberValue == 1)
        }

        // send new data to parent        
        this.props.onFilterChange(newData)  
       
        
    }
      
  render() {

        return(
            <div className="RatingReviewsFilter col-sm-12">
                <p>{i18next.t("FilterBy")}</p>
                <select onChange={(e) => this.changeOption(e, "sortingOrder")} >
                    {sortOrder.map((filtr, index) => {
                        return <option value={filtr[0]} key={index}>{filtr[1]}</option>
                    })}
                </select>

                <select onChange={(e) => this.changeOption(e, "scoresFilter")} >
                    {filters.map((filtr, index) => {
                        return <option value={filtr[0]} key={index}>{filtr[1]}</option>
                    })}
                </select>
            </div>
        )
    }
}

export default RatingReviewsFilter;
