import React from 'react';
import { loader as gqlLoader } from "graphql.macro";
import { Query } from "react-apollo";
import {DotLoader} from "react-spinners";
import {css} from "@emotion/react";
import ReactComponent from "../../ReactComponent";
import './parentRoute.css';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const ParentRouteQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;


class ParentRoute extends ReactComponent{

    constructor(props) {
        super(props);
        this.state = {
          error: null,
          isLoaded: false,
          ListParents: ''
        };
    }

    render(){
        return(
            <Query query={ParentRouteQuery} variables={{indexname: this.indexName, rootpath: '/sitecore/content/' + this.appName + '/', currrentContextItem: this.props.CurrentId}}>
                {({ loading, error, data}) => {
                    if (loading)
                        return (
                            <div className='sweet-loading' style={{marginTop: '50px', marginBottom: '50px'}}>
                                <DotLoader
                                    css={override}
                                    sizeUnit={"px"}
                                    size={50}
                                    color={'#9fce4e'}
                                    loading={!this.state.isLoaded} 
                                />
                            </div>
                        );
                    if (error)
                        return (
                            <div>Error: {error.message}</div>
                        );
                    return (
                        <>
                        
                            <nav className="breadcrumb__custom">
                                {
                                    data.breadcrumbs && JSON.parse(data.breadcrumbs).ListParents.map((item, index) => (
                                            <div key={index}>
                                                <a href={item.Link}>{item.Text}</a>&nbsp;/&nbsp;
                                            </div>
                                        )
                                    )
                                }
                                {this.props.CurrentItemName}
                            </nav>
                        </>
                    )
                }}
            </Query>
        );
    }

}
export default  withSitecoreContext()(ParentRoute);
