import React from 'react';
import { NavLink } from "react-router-dom";
import { Text } from '@sitecore-jss/sitecore-jss-react';

class MiniCart extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
      cartItems: "",
      totalCount: 0,
      totalPrice: 0,
      minicartdisplay: ""
    }
    this.index = -1;
    this.handleMouseover = this.handleMouseover.bind(this);
    this.handleMouseout = this.handleMouseout.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
		if(sessionStorage.getItem('cartItems')){
			let sessionItems = sessionStorage.getItem('cartItems')
			let getSessionItems =  sessionItems !== null  ?  JSON.parse(sessionItems) : ""

			if(getSessionItems !== "") {
        this.setState({
          cartItems: getSessionItems
        })

        let itemTotal = 0;
        let totalPrice = 0;
        for(var i = 0;i<getSessionItems.length;i++) {
          itemTotal = parseInt(itemTotal) + parseInt(getSessionItems[i].quantity);
          totalPrice = parseInt(totalPrice) + (parseInt(getSessionItems[i].quantity) * getSessionItems[i].price)
        } 
        this.setState({
          totalCount: itemTotal,
          totalPrice: totalPrice
        })
      }      
		}
  }

  handleMouseover() {
    this.setState({
      minicartdisplay: "minicart-display"
    })
  }

  handleMouseout() {
    this.setState({
      minicartdisplay: ""
    })
  }

  handleClick(event,index) {
    let sessionItems = sessionStorage.getItem('cartItems')
    let getSessionItems =  sessionItems !== null  ?  sessionItems : ""

    if(getSessionItems) {
      let items = JSON.parse(getSessionItems);
      if(index>=0) {
        items.splice(index, 1);
        this.setState({
          cartItems: items
        })
        sessionStorage.setItem('cartItems', JSON.stringify(items));  
      }
    }
  }
  
  render() {        
    return(
    <div className="navbar-minicart navbar-minicart-topbar" onMouseOver={this.handleMouseover} >
      <div className="navbar-minicart">
        <NavLink className="minicart-link" to="/cart">
          <span className="minicart-icon">
            <svg xmlSpace="preserve" viewBox="0 0 459.529 459.529" y="0px" x="0px" xmlnsXlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" version="1.1">
              <g>
                <g>
                  <path d="M17,55.231h48.733l69.417,251.033c1.983,7.367,8.783,12.467,16.433,12.467h213.35c6.8,0,12.75-3.967,15.583-10.2
                        l77.633-178.5c2.267-5.383,1.7-11.333-1.417-16.15c-3.117-4.817-8.5-7.65-14.167-7.65H206.833c-9.35,0-17,7.65-17,17
                        s7.65,17,17,17H416.5l-62.9,144.5H164.333L94.917,33.698c-1.983-7.367-8.783-12.467-16.433-12.467H17c-9.35,0-17,7.65-17,17
                        S7.65,55.231,17,55.231z"/>
                  <path d="M135.433,438.298c21.25,0,38.533-17.283,38.533-38.533s-17.283-38.533-38.533-38.533S96.9,378.514,96.9,399.764
                        S114.183,438.298,135.433,438.298z"/>
                  <path d="M376.267,438.298c0.85,0,1.983,0,2.833,0c10.2-0.85,19.55-5.383,26.35-13.317c6.8-7.65,9.917-17.567,9.35-28.05
                        c-1.417-20.967-19.833-37.117-41.083-35.7c-21.25,1.417-37.117,20.117-35.7,41.083
                        C339.433,422.431,356.15,438.298,376.267,438.298z"/>
                </g>
              </g>
            </svg>
            <span>{this.state.totalCount}</span>
          </span>
        </NavLink>
        <div className={`minicart ${this.state.minicartdisplay}`}  onMouseOver={this.handleMouseover} onMouseOut={this.handleMouseout}>
          {this.state.totalCount == 0 && 
            <div className="minicart-header no-items show">Your shopping bag is empty.</div>
          }          
          <div className="minicart-body">
            <div className="minicart-body-wrap">
            {this.state.cartItems && this.state.cartItems.map((product, index) => (              
              <div key={index} className="cart-product clearfix">
                <div className="cart-product-image"> 
                  <a className="cart-product-img" href={product.producturl}>
                    <img width="100" height="100" src={product.productimg} className="attachment-shop_thumbnail size-shop_thumbnail wp-post-image" alt="" srcSet={`${product.productimg} 100w, ${product.productimg} 150w, ${product.productimg} 300w, ${product.productimg} 600w`} sizes="(max-width: 100px) 100vw, 100px" />
                  </a>
                </div>
                <div className="cart-product-details">
                  <div className="cart-product-title"><a href={product.producturl}>{product.productname.value}</a></div>
                  <div className="cart-product-quantity-price">{product.quantity} x <span className="amount">${product.price}</span></div>
                </div>
                <a href="#" className="remove" title="Remove this item" onClick={(event)=>this.handleClick(event,index)}>×</a>
              </div>                 
            ))              
            }
            </div>            
          </div>
          <div className="minicart-footer">
            {this.state.totalCount > 0 &&
              <>
              <div className="minicart-total">Cart Subtotal: <span className="amount">${this.state.totalPrice}</span></div><div className="minicart-actions clearfix"><a className="checkout-button button" href="#"><span className="text">Checkout</span></a></div>
              </>
            }
            {this.state.totalCount == 0 && 
              <>
              <div className="minicart-actions clearfix"><a className="button" href="/cart"><span className="text">Go to the shop</span></a></div>
              </>
            }
          </div>
        </div>
      </div>
    </div>  
    );
  }

}

export default MiniCart;
