import React from 'react';
import { NavLink } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';
import {Link, Text, RichText, isExperienceEditorActive} from '@sitecore-jss/sitecore-jss-react';

class CarouselHome extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      opacity: 0,
      activeDot: 0
    };
    this.setActiveDot = this.setActiveDot.bind(this);
  }

  setActiveDot(dot) {
    this.setState({
      activeDot: dot
    });
  }

  render () {

    let options = {
      items: 1,
      loop: true,
      nav: true,
      navText: [`<div class="tp-leftarrow tparrows custom  noSwipe"
      style="top: 50%; transform: matrix(1, 0, 0, 1, 30, -20); left: 0px; opacity: 0; transition:all 0.5s ease;"></div>`,
        `<div class="tp-rightarrow tparrows custom  noSwipe"
      style="top: 50%; transform: matrix(1, 0, 0, 1, -70, -20); left: 100%; opacity: 0; transition:all 0.5s ease;"></div>`],
      dots: true,
      autoplay: true,
      autoplayTimeout: 10000,
      autoplayHoverPause: true,
      // animateIn: //class for css3 animation,
      // animateOut: //class for css3 animation,
    };

    const slidesList = this.props.fields.Slides;

    let dots = [];
    for (let i = 0; i <= slidesList.length; i++) {
      dots.push(
          <div className={"owl-dot" + this.state.activeDot === i ? "selected" : ""}
               key={i}
               onClick={() => this.setActiveDot(i)}>
          </div>
      );
    }

    const designVariant = typeof this.props.fields.designVariant === 'undefined' ? "" : this.props.fields.designVariant.value;

    return (
        <div className={"section "+designVariant}>
          <div className="container-fluid">
            <div className="row homeCarousel" style={{ maxHeight: '505px' }}>
              {isExperienceEditorActive() || !isExperienceEditorActive() && (
                <OwlCarousel options={options} style={{ maxHeight: '505px' }}>
                {slidesList &&
                slidesList.map((listItem, index) => (
                    <li key={`listItem-${index}`} style={{maxHeight: '505px'}}>
                      {listItem.fields.Banner.value.src ?
                        <div style={{
                          backgroundImage: `url(` + listItem.fields.Banner.value.src + `)`,
                          backgroundRepeat: 'np-reapeat',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center center',
                          width: '100%',
                          height: '505px',
                          opacity: '1',
                          visibility: 'inherit',
                          zIndex: '20'
                        }}>
                          {listItem.fields.Subtitle.value !== '' &&
                            <div style={{textAlign: 'center', zIndex: '21'}}>
                              <div className="tp-caption business_small_white tp-resizeme"
                                   style={{
                                     display: 'inline-block',
                                     position: 'relative',
                                     marginTop: '150px',
                                     fontSize: '1.4vw'
                                   }}>
                                <Text field={listItem.fields.Subtitle}/>
                              </div>
                            </div>
                          }
                          {listItem.fields.Title.value !== '' &&
                            <div style={{textAlign: 'center', zIndex: '21'}}>
                              <div className="tp-caption business_big_white tp-resizeme"
                                   style={{
                                     display: 'inline-block',
                                     position: 'relative',
                                     marginTop: '30px',
                                     marginBottom: '30px',
                                     fontSize: '3vw'
                                   }}>
                                <RichText field={listItem.fields.Title}/>
                              </div>
                            </div>
                          }
                          {listItem.fields.ButtonLeft.value.href !== '' || listItem.fields.ButtonRight.value.href !== '' ?
                            (<div style={{textAlign: 'center', zIndex: '21'}}>
                              {listItem.fields.ButtonLeft.value.href !== '' &&
                                <div style={{display: 'inline-block', marginRight: '10px'}}>
                                  <NavLink title={listItem.fields.ButtonLeft.value.title} to={listItem.fields.ButtonLeft.value.href}
                                    className="btn btn-white-outline"
                                    style={{
                                      transition: 'none 0s ease 0s', lineHeight: '20px',
                                      borderWidth: '2px', margin: '0px', padding: '8px 28px',
                                      letterSpacing: '0px', fontWeight: '600', fontSize: '13px'
                                    }}>{listItem.fields.ButtonLeft.value.text}</NavLink>
                                </div>
                              }
                              {listItem.fields.ButtonRight.value.href !== '' &&
                                <div style={{display: 'inline-block', marginLeft: '10px'}}>
                                  <NavLink title={listItem.fields.ButtonRight.value.title} to={listItem.fields.ButtonRight.value.href}
                                    className="btn btn-white-outline"
                                    style={{
                                      transition: 'none 0s ease 0s', lineHeight: '20px',
                                      borderWidth: '2px', margin: '0px', padding: '8px 28px',
                                      letterSpacing: '0px', fontWeight: '600', fontSize: '13px'
                                    }}>{listItem.fields.ButtonRight.value.text}</NavLink>
                                </div>
                              }
                            </div>) : {}
                          }
                        </div>
                      :
                        <>
                          {listItem.fields.LinkVideo.value.href && listItem.fields.LinkVideo.value.linktype === 'external' &&
                            <iframe title="youtube" width="1920" height="505" src={(listItem.fields.LinkVideo.value.url).replace('https://youtu.be/', 'https://www.youtube.com/embed/')}/>
                          }
                          {listItem.fields.LinkVideo.value.href && listItem.fields.LinkVideo.value.linktype === 'media' &&
                            <video style={{width: '100%', height: '505px'}} className="video-fluid" loop autoPlay muted>
                              <source src={listItem.fields.LinkVideo.value.href} type="video/mp4"/>
                            </video>
                          }
                          {listItem.fields.LinkVideo.value.linktype !== 'external' &&
                            <div style={{
                              position: 'absolute',
                              top: '0',
                              width: '100%',
                              height: '505px',
                              opacity: '1',
                              visibility: 'inherit',
                              zIndex: '20'
                            }}>
                              {listItem.fields.Subtitle.value !== '' &&
                                <div style={{textAlign: 'center', zIndex: '21'}}>
                                  <div className="tp-caption business_small_white tp-resizeme"
                                       style={{
                                         display: 'inline-block',
                                         position: 'relative',
                                         marginTop: '150px',
                                         fontSize: '1.4vw'
                                       }}>
                                    <Text field={listItem.fields.Subtitle}/>
                                  </div>
                                </div>
                              }
                              {listItem.fields.Title.value !== '' &&
                                <div style={{textAlign: 'center', zIndex: '21'}}>
                                  <div className="tp-caption business_big_white tp-resizeme"
                                       style={{
                                         display: 'inline-block',
                                         position: 'relative',
                                         marginTop: '30px',
                                         marginBottom: '30px',
                                         fontSize: '3vw'
                                       }}>
                                    <RichText field={listItem.fields.Title}/>
                                  </div>
                                </div>
                              }
                              {listItem.fields.ButtonLeft.value.href !== '' || listItem.fields.ButtonRight.value.href !== '' ?
                                  (<div style={{textAlign: 'center', zIndex: '21'}}>
                                    {listItem.fields.ButtonLeft.value.href !== '' &&
                                    <div style={{display: 'inline-block', marginRight: '10px'}}>
                                      <NavLink title={listItem.fields.ButtonLeft.value.title} to={listItem.fields.ButtonLeft.value.href}
                                    className="btn btn-white-outline"
                                    style={{
                                      transition: 'none 0s ease 0s', lineHeight: '20px',
                                      borderWidth: '2px', margin: '0px', padding: '8px 28px',
                                      letterSpacing: '0px', fontWeight: '600', fontSize: '13px'
                                    }}>{listItem.fields.ButtonLeft.value.text}</NavLink>
                                    </div>
                                    }
                                    {listItem.fields.ButtonRight.value.href !== '' &&
                                    <div style={{display: 'inline-block', marginLeft: '10px'}}>
                                      <NavLink title={listItem.fields.ButtonRight.value.title} to={listItem.fields.ButtonRight.value.href}
                                    className="btn btn-white-outline"
                                    style={{
                                      transition: 'none 0s ease 0s', lineHeight: '20px',
                                      borderWidth: '2px', margin: '0px', padding: '8px 28px',
                                      letterSpacing: '0px', fontWeight: '600', fontSize: '13px'
                                    }}>{listItem.fields.ButtonRight.value.text}</NavLink>
                                    </div>
                                    }
                                  </div>) : {}
                              }
                            </div>
                          }
                        </>
                      }
                    </li>
                  ))}
              </OwlCarousel>
              )}
            </div>
          </div>
        </div>
    );
  }
}

export default CarouselHome;
