import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { matchPath } from 'react-router';
import TagManager from 'react-gtm-module'

class Tagging extends React.Component {
  

  componentDidMount() {
  	// Load only 
    
  	// dataLayer Analytics
    const TagManagerArgs = {
      gtmId: 'GTM-5FG9B38',
      dataLayer: {
        'brand': 'LPB',
        'language': 'es',
        'country': 'es',
        'siteTypeLevel': 'main',
        'pageCategory': this.props.sitecoreContext.route.fields.pageCategoryGTM.value,
        // 'virtualPageURL': this.props.location,
        // 'virtualPageTitle': this.props.pageTitle
      }
    }
 

    // Initialize TagManager once
    TagManager.initialize(TagManagerArgs)
    



}
  // updateDataLayerPageview(){
  // 	window.dataLayer = window.dataLayer || [];
  // 	var json = {
  //     'brand':'LPB',
  //     'language': 'it',
  //     'country': 'it',
	// 	  'siteTypeLevel': 'main',
  // 		'pageCategory': this.props.sitecoreContext.route.fields.pageCategoryGTM.value,
  // 	}
  // 	window.dataLayer.push(json)

  // }

  componentDidUpdate(prevProps, prevState) {
	  Object.entries(this.props).forEach(([key, val]) => {
		  	if(prevProps[key] !== val){
		  		if(key == 'pageTitle') {
		  		//	this.updateDataLayerPageview()
		  		}
		  	}
		  }
	  );
	}



  render() {

    return (
      <div>
     	
      </div>
    );
  }
}

export default withSitecoreContext()(Tagging);