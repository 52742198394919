import React from 'react';
import { NavLink } from "react-router-dom";
import { Text, Image, RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import './FeaturedPosts.css';

const FeaturedPosts = (props) => {
  const {FeaturedPostsContentList, designVariant} = props.fields;

  let columnClasses = "";
  if(designVariant.value == "type-1" || designVariant.value == "type-5" || designVariant.value == "type-blog"){
    columnClasses = "col-md-4";
  }
  else if(designVariant.value == "type-2"){
    columnClasses = "col-md-6"
  }
  else if(designVariant.value == "type-3"){
    columnClasses = "col-md-3"
  }

  if(designVariant.value != "type-4"){
    return (
            
            <div className={"FeaturedPosts " + designVariant.value}> 
              
              <div className="section pt-7" >
                <div className="container">
                  <div className="row">
                    <div data-wow-delay="0.5s" className="col-md-12 col-sm-12 wow fadeInUp">
                      {props.fields.Title.value && 
                        <h2 className="text-center mb-2 decorated-title" ><Text field={props.fields.Title} /></h2>
                      }
                      {props.fields.Text.value &&
                      <div className="text-center mb-4" ><Text field={props.fields.Text} /></div> 
                      }
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="section section-ctn mt-2 pb-4"> 
                <div className="container">
                  <div className="row">
                    {FeaturedPostsContentList &&    
                      FeaturedPostsContentList.map((listItem, index) => (
                        <React.Fragment key={`sharedListItem-${index}`}>
                        {/* If external link */}
                        {listItem.fields.PostLink.value.linktype && listItem.fields.PostLink.value.href.startsWith("http") === true ? (
                          <a className={`${columnClasses} col-sm-12 item`}  target="_blank" href={listItem.fields.PostLink.value.href}>
                            <div className="text-center img-ctn">
                              <img src={listItem.fields.Icon && listItem.fields.Icon.value.src}/>
                            </div>              
                            <h4 className="text-center">
                              <Text field={listItem.fields.title} />        
                            </h4>
                            <div className="text-center richtext-ctn"><RichText field={listItem.fields.content} /></div>
                          </a>
                          //if internal link
                           ) : (
                          <NavLink className={`${columnClasses} col-sm-12 item`} key={`sharedListItem-${index}`} to={listItem.fields.PostLink.value.href}>
                            <div className="text-center img-ctn">
                              <img src={listItem.fields.Icon && listItem.fields.Icon.value.src}/>
                            </div>              
                            <h4 className="text-center">
                              <Text field={listItem.fields.title} />        
                            </h4>
                            <div className="text-center richtext-ctn"><RichText field={listItem.fields.content} /></div>
                          </NavLink>
                          )}
                        </React.Fragment>               
                      ))} 
                  </div>
                </div>
              </div>
          </div>        
    );
    // TYPE-4 ONLY
  }else{
    return(
      <div className={"FeaturedPosts " + designVariant.value}> 
        <div className="container">
          <div className="section section-ctn mt-2 pb-4"> 
            <h2 className="text-center mb-2 title" ><Text field={props.fields.Title} /></h2>
              <div className="row">
                {FeaturedPostsContentList &&    
                  FeaturedPostsContentList.map((listItem, index) => (
                    <div className={`${columnClasses} col-sm-12 item`} key={`sharedListItem-${index}`}>
                      <div  >
                        <h4 className="text-center">
                          <Text field={listItem.fields.title} />        
                        </h4>
                        <div className="text-center img-ctn">
                          <img src={listItem.fields.Icon && listItem.fields.Icon.value.src}/>
                        </div>              
                        <div className="text-center richtext-ctn"><RichText field={listItem.fields.content} /></div>
                      </div>
                    </div>                
                  ))} 
              </div>
            </div>
      </div>
  </div>  
    )                  
   }
}
export default FeaturedPosts;
