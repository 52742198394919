import React from 'react';
import { NavLink } from "react-router-dom";
import { Image, Link } from '@sitecore-jss/sitecore-jss-react';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../lib/GraphQLData';
import $ from 'jquery/dist/jquery';
import './HorizontalMenu.css';
import context from "../../utils/Context";

const HorizontalMenuQuery = gqlLoader('./HorizontalMenu.graphql');
const ContentPath = '/sitecore/content/' + context.getAppName() + '/Content/Header';

class HorizontalMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            isMounted: false,
            totalCount: 0,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {


        setTimeout(this.superfishInit, 2000);
        
        //$(window).on("resize", this.superfishInit);
        $(window).scroll(function() {
            //sticky navbar
            var e = window,
                a = "inner";
            if (!("innerWidth" in window)) {
                a = "client";
                e = document.documentElement || document.body;
            }
            var width = e[a + "Width"];
             if (width > 992) {
                 if ($(this).scrollTop() > 100) {
                     $(".main-hd").addClass("navbar-small");
                 } else {
                     $(".main-hd").removeClass("navbar-small");
                 }
             }
        });

        $(window).on("resize", headerResizes)

        headerResizes()

        function headerResizes() {
            if(window.innerWidth <=900){
                $(".main-hd").removeClass("navbar-small");
                $(".main-hd").addClass("mobile-mode");
            }else{
                $(".main-hd").removeClass("mobile-mode");
                
            }

            if(window.innerWidth <= 1400 && !$(".main-hd").hasClass("navbar-small")){
                $(".navbar-brand").addClass("small-logo");
            }else{
                $(".navbar-brand").removeClass("small-logo");
            }

        }

        this.setState({
            isMounted: true
        });

        
    }



    superfishInit() {
        
        if($ !== undefined && window.$.fn.superfish !== undefined){

            $.fn.superfish = window.$.fn.superfish;
            $(".primary-nav").superfish({
                anchorClass: ".dropdown", // selector within menu context to define the submenu element to be revealed
                hoverClass: "open", // the class applied to hovered list items
                pathClass: "overideThisToUse", // the class you have applied to list items that lead to the current page
                pathLevels: 1, // the number of levels of submenus that remain open or are restored using pathClass
                delay: 650, // the delay in milliseconds that the mouse can remain outside a submenu without it closing
                animation: {
                    opacity: "show"
                }, // an object equivalent to first parameter of jQuery’s .animate() method. Used to animate the submenu open
                animationOut: {
                    opacity: "hide"
                }, // an object equivalent to first parameter of jQuery’s .animate() method Used to animate the submenu closed
                speed: "fast", // speed of the opening animation. Equivalent to second parameter of jQuery’s .animate() method
                speedOut: "fast", // speed of the closing animation. Equivalent to second parameter of jQuery’s .animate() method
                cssArrows: true, // set to false if you want to remove the CSS-based arrow triangles
                disableHI: false, // set to true to disable hoverIntent detection
            });
        }




        $(document).on("click", ".header-search-overlay .close", function() {
            $(".header-search-overlay").stop(true, true).animate({
                "opacity": 0
            }, 600, "swing", function() {
                $(this).addClass("hide");
            });
        });


        // Off Canvas menu
         $(".navbar-toggle").unbind().on("click", (e) => {
             e.stopPropagation();
             e.preventDefault();

             $("body").toggleClass("open-offcanvas");
             $(".navbar-toggle").toggleClass("x");

         });

        $("body").on("mousedown", $.proxy(function(e) {
            var element = $(e.target);
            if ($(".offcanvas").length && $("body").hasClass("open-offcanvas")) {
                if (!element.is(".offcanvas") && element.parents(".offcanvas").length === 0 && !element.is(".navbar-toggle") && element.parents(".navbar-toggle").length === 0 && !element.is(".navbar-offcanvas-btn") && element.parents(".navbar-offcanvas-btn").length === 0) {
                    $("body").removeClass("open-offcanvas");
                    $(".navbar-toggle").removeClass("x");
                }
            }
        }, this));

        $(".menu-item-has-children .caret").off("click").on("click", function(e) {
	        e.stopPropagation();
	        e.preventDefault();
	        var dropdown = $(this).closest(".dropdown, .dropdown-submenu");
	        if (dropdown.hasClass("open")) {
	            dropdown.removeClass("open");
	        } else {
	            dropdown.addClass("open");
	        }
	    });
        

            
        
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }


    render(){

        const graphQLResult = this.props.MenuQuery;
        const { datasource } = graphQLResult;

        let headerClass = "header-container header-type-classic header-scroll-resize";
        for(var component of this.props.sitecoreContext.route.placeholders["jss-heading"]) {
            if (component.componentName === "HeadingBanner") {
                headerClass = "header-container header-type-classic header-absolute header-transparent header-scroll-resize";
            }
            if (component.componentName === "HeadingSmallBanner") {
                headerClass = "header-container header-type-classic header-scroll-resize";
            }
        }

        return (
            <>
                <div className={"header-offcanvas navbar-offcanvas offcanvas open "}>
                    {datasource && (
                        <div className="offcanvas-wrap">
                            <nav className="offcanvas-navbar mobile-offcanvas-navbar">
                                <ul className="offcanvas-nav">
                                    {datasource && datasource.children.map((listItem, index) => (
                                        <li key={`sharedListItem-${index}`} className="menu-item-has-children dropdown">
                                            {listItem && listItem.internalLink && listItem.internalLink.url.startsWith("http") === true ?
                                                <Link field={listItem.internalLink} className="dropdown-hover">
                                                    <span className="underline">{listItem.title.value}&nbsp;</span>
                                                    {listItem.children.length > 0 && <span className="caret"></span>}
                                                </Link>
                                                :
                                                <NavLink className="dropdown-hover" to={listItem.internalLink.url} >
                                                    <span className="underline">{listItem.title.value}&nbsp;</span>
                                                    {listItem.children.length > 0 && <span className="caret"></span>}
                                                </NavLink>
                                            }
                                            {listItem.children.length > 0 &&
                                            <ul className="dropdown-menu">
                                                {
                                                    listItem.children.map((subListItem, index) => (
                                                        <li key={`sharedSubListItem-${index}`} className="menu-item">
                                                            {subListItem && subListItem.internalLink && subListItem.internalLink.url.startsWith("http") === true ?  
                                                            <Link field={subListItem.internalLink} />
                                                            :
                                                            <NavLink to={subListItem.internalLink.url} >
                                                                {subListItem.title.value}
                                                            </NavLink>                                                            
                                                            }                                                          
     
                                                        </li>
                                                    ))}
                                            </ul>
                                            }
                                        </li>
                                    ))
                                    }
                                </ul>
                            </nav>
                            <div className="navbar-toggle-fixed">
                                <button type="button" className="navbar-toggle x">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar bar-top"></span>
                                    <span className="icon-bar bar-middle"></span>
                                    <span className="icon-bar bar-bottom"></span>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="offcanvas-overlay"></div>
                <header id="header" className={headerClass}>
                    {datasource && (
                        <div className="navbar-container">
                            <div className="navbar navbar-default navbar-scroll-fixed">
                                <div className="navbar-default-container">
                                    <div className="navbar-default-wrap">
                                        <div className="container">
                                            <div className="row">
                                                <div className="navbar-default-col">
                                                    <div className="navbar-wrap">
                                                        <div className="navbar-header">
                                                            <button type="button" className="navbar-toggle">
                                                                <span className="sr-only">Toggle navigation</span>
                                                                <span className="icon-bar bar-top"></span>
                                                                <span className="icon-bar bar-middle"></span>
                                                                <span className="icon-bar bar-bottom"></span>
                                                            </button>
                                                            <NavLink className="navbar-brand" to="/">
                                                                {headerClass === "header-container header-type-classic header-absolute header-transparent header-scroll-resize"
                                                                    ? <img src={datasource.sitelogoTransparent && datasource.sitelogoTransparent.src} className="logo" alt="Logo" />
                                                                    : <img src={datasource.sitelogo && datasource.sitelogo.src} className="logo" alt="Logo" />
                                                                }
                                                                <img src={datasource.sitelogoFixed && datasource.sitelogoFixed.src} className="logo-fixed" alt="Logo" />
                                                                <img src={datasource.sitelogoMobile && datasource.sitelogoMobile.src} className="logo-mobile" alt="Logo" />
                                                            </NavLink>
                                                        </div>
                                                        <nav className="collapse navbar-collapse primary-navbar-collapse">
                                                            <ul className="nav navbar-nav primary-nav">
                                                                {datasource.children.map((listItem, index) => (
                                                                        
                                                                        <li key={`sharedListItem-${index}`} className={`menu-item-has-children dropdown ${index > 4 ? "align-right":""}`}>
                                                                            {listItem && listItem.internalLink && listItem.internalLink.url.startsWith("http") === true ?  
                                                                            <Link field={listItem.internalLink} className="dropdown-hover">
                                                                                {listItem.title.value}&nbsp;
                                                                                {listItem.children.length > 0 && <span className="caret"></span>}                                                                            
                                                                            </Link>
                                                                            :
                                                                            <NavLink className="dropdown-hover" to={listItem.internalLink.url} >
                                                                                {listItem.title.value}&nbsp;
                                                                                {listItem.children.length > 0 && <span className="caret"></span>}
                                                                            </NavLink>
                                                                            }
                                                                            {listItem.children.length > 0 &&
                                                                            <ul className="dropdown-menu" style={{display: 'none'}}>
                                                                                {
                                                                                    listItem.children.map((subListItem, index) => (
                                                                                        <li key={`sharedSubListItem-${index}`} className="menu-item">
                                                                                            {subListItem && subListItem.internalLink && subListItem.internalLink.url.startsWith("http") === true ?  
                                                                                            <Link field={subListItem.internalLink} />
                                                                                            :
                                                                                            <NavLink to={subListItem.internalLink.url} >
                                                                                                {subListItem.title.value}
                                                                                            </NavLink>
                                                                                            }
                                                                                        </li>
                                                                                    ))}
                                                                            </ul>
                                                                            }
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </nav>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </header>
            </>
        );
    }
}
export default GraphQLData(HorizontalMenuQuery, { name: 'MenuQuery', options: {variables: {path: ContentPath}}  })(HorizontalMenu);
