import React from 'react';

class AddToCart extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
      cartItems: "",
      quantity: 1
    }
    
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  componentDidMount() {
		if(sessionStorage.getItem('cartItems')){
			let sessionItems = sessionStorage.getItem('cartItems')
			let getSessionItems =  sessionItems !== null  ?  sessionItems : ""

			if(getSessionItems !== "") {
        this.setState({
          cartItems: getSessionItems
        })
      }      
		}
	}

  handleChange(event) {
    this.setState({quantity: event.target.value});
  }

  handleSubmit(event) {
    //event.preventDefault();


    let sessionItems = sessionStorage.getItem('cartItems')
    let getSessionItems =  sessionItems !== null  ?  sessionItems : ""

    this.setState({
      cartItems: getSessionItems
    })

    if(getSessionItems) {
        let items = JSON.parse(getSessionItems);
        let idx = items.findIndex(i => i.id == this.props.productid);
        if(idx>=0) {
          items[idx].quantity = parseInt(items[idx].quantity) + parseInt(this.state.quantity);
        }
        else {
          let item = {id: this.props.productid, productname: this.props.productname, price: this.props.price, quantity: this.state.quantity, productimg: this.props.productimg, producturl: this.props.producturl}    
          items.push(item);
        }
        this.setState({
          cartItems: JSON.stringify(items)
        })
        sessionStorage.setItem("cartItems", JSON.stringify(items));
        if(this.props.singlepage == "true") {
          sessionStorage.setItem("AddToCart", "cartmessage")
        }        
    }
    else {
      let item = {id: this.props.productid, productname: this.props.productname, price: this.props.price, quantity: this.state.quantity, productimg: this.props.productimg, producturl: this.props.producturl}

      let items = []
      items.push(item);        
      this.setState({
        cartItems: JSON.stringify(items)
      })
      sessionStorage.setItem("cartItems", JSON.stringify(items));
      if(this.props.singlepage == "true") {
        sessionStorage.setItem("AddToCart", "cartmessage")
      }        
    }
  }

  render() {
  	
    return(
      <form className="cart" onSubmit={this.handleSubmit} >
        <input type="hidden" name="productId" disabled={this.props.disabled} value={this.props.productid} /> 
        <div className="add-to-cart-table">
          <div className="quantity">
            <input type="number" step="1" min="1" name="quantity" value={this.state.quantity} title="Qty" className="input-text qty text" size="4" onChange={this.handleChange} />
          </div>
          <button type="submit" className="single_add_to_cart_button button">Add to Cart</button>
        </div>
      </form>
    );
  }
}	

export default AddToCart;
