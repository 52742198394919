import React from 'react';
import context from "./utils/Context";
import packageJson from '../package.json';
import configFile from '../src/temp/config';

class ReactComponent extends React.Component{

    indexName;
    appName;
    graphQLEndpoint;

    constructor(props){
        super(props);
        this.indexName = context.getIndexName(props);
        this.appName = context.getAppName();
        this.getGraphqlEndpoint = context.getGraphQLEndPoint();
    }

}

export default ReactComponent;
