import React from 'react';
import { Image, Link, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ReactComponent from "../../ReactComponent";
import "./salonList.scss";

class SalonList extends ReactComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };
    }

    render() {
        return (
            this.props.fields &&
                this.props.fields.SalonList && this.props.fields.SalonList.map((salonItem, salonIndex) => (
                    <div className="col-md-3 salons">
                        <a className="dropdown-item" href={salonItem.fields && salonItem.fields.SalonLink.value.href} key={salonIndex}>
                            <img src={salonItem.fields.Brand.fields && salonItem.fields.Brand.fields.SalonImage && salonItem.fields.Brand.fields.SalonImage.value.src}/>
                        </a>
                    </div>
                ))
        )
    }
}

export default withSitecoreContext()(SalonList);
