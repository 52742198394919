import React from 'react';
import { Link, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './socialMediaArticles.css';

class SocialMediaArticles extends React.Component {
  render() {
    return(
      <div className="row_custom_small margin_Bio text-center">
        <ul className="social">
            {this.props.fields.SocialMediaLinksList && this.props.fields.SocialMediaLinksList.map((socialLinkItem,index) =>(
                <li key={index} className="social-item">
                    <Link field={socialLinkItem.fields.InternalLink} className="text-dark social-link" target="_blank">
                        <img src={socialLinkItem.fields.Image && socialLinkItem.fields.Image.value.src} className="img_custom_full"/>
                    </Link>
                </li>
            ))}
        </ul>
      </div>
    )
  }
}

export default withSitecoreContext()(SocialMediaArticles);
