import React from 'react';
import { NavLink } from "react-router-dom";
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import StarRatingV2 from '../StarRatingV2';
import ProductReviews from '../../utils/ProductReviews';
import 'lightbox-react/style.css';

// PROPS : 
// - product (graphql product item, required)
// - listView (boolean, optional)
class ProductGridItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
      isModalOpen: false,
    };
  }



  render() {
    const product = this.props.product;

    let images = [];
    if (product.item.image1) images.push(product.item.image1.src);
    if (product.item.image2) images.push(product.item.image2.src);
    if (product.item.image3) images.push(product.item.image3.src);
    if (product.item.image4) images.push(product.item.image4.src);

    const productUrl = (product.item.url) ? product.item.url : "#"

    return (

      <div className={"text-center " + product.item.productIcons.value}>
        <NavLink to={product.item.url}>
          
          <div className="portfolio-link">
              <img src={product.item.image1 && product.item.image1.src} className="img-fluid" />
              
              <div className="portfolio-caption min_font">
                  
                  <Text field={product.item.title} tag="h4" /> 
                  <div className="rating">
                    <StarRatingV2 value={product.item.rating.numberValue} />
                    <ProductReviews productId={product.item.id} />
                  </div>
              </div>
          </div>
          <div className="btn_custom_oro"><span>Descubrir<i className="fas fa-long-arrow-alt-right"></i></span></div>
        </NavLink>
      </div>
    );
  }
}

export default ProductGridItem;
