import React from 'react';
import { NavLink } from "react-router-dom";
import { Text, Image, Link } from '@sitecore-jss/sitecore-jss-react';
import i18next from 'i18next';


function LinkListRendered(props) {

    if (props.item.fields.InternalLink.value.linktype === "javascript") {
        let jsValue = props.item.fields.InternalLink.value.href.replace(";return false;", ";");
        let classValue = props.item.fields.InternalLink.value.class;
        return (
            <a
                href={jsValue}
                className={`helv_lt ${classValue} `}>
                <Text field={props.item.fields.Title} />
            </a>
        )
    }
    else if (props.item.fields.InternalLink.value.linktype === "internal") {
         return (          
           <NavLink
             to={props.item.fields.InternalLink.value.href}
             className="helv_lt">
             <Text field={props.item.fields.Title} />
           </NavLink>      
         )
     }
    else {
         return (
           <a href={props.item.fields.InternalLink.value.href} className="helv_rom" >{props.item.fields.Title.value}</a>
         )
    }
}


class FooterMultilevel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {SocialLinks} = this.props.fields;
    const {MenuLinks} = this.props.fields;

    return(
      <footer className="main-ft">
        <div className="row_custom_small top-ft">
          <div className="menu_ctn">
            <p className="logo_ctn">
              <NavLink to={"/"} >
                <img className="logo img_custom_full" src={this.props.fields.Logo && this.props.fields.Logo.value.src} />
              </NavLink>
            </p>
           <ul className="col-xs-12 sidebar_bot pl-0 pb-0">

             {this.props.fields && this.props.fields.SubFooterMultilevel.map((footerMulti) => (
                 footerMulti.fields && footerMulti.fields.HeaderMenu.map((mainMenu, mainindex) =>
                    <li id={`mainind_${mainindex}`} className="col-md-2 pl-0 pr-0 item-col" key={`foot-${mainindex}`}>
                   {mainMenu.fields.Title &&
                      mainMenu.fields.InternalLink && mainMenu.fields.InternalLink.value.href != '' &&
                       (
                           mainMenu.fields.InternalLink.value.href && mainMenu.fields.InternalLink.value.href.startsWith("http") === true ?
                               <Link field={mainMenu.fields.InternalLink} className="helv_rom top_level"/>
                               :
                               <NavLink to={mainMenu.fields.InternalLink.value.href} className="helv_rom top_level">
                                 <Text field={mainMenu.fields.Title} />
                               </NavLink>
                       )
                   }
                   {mainMenu.fields.SubMenuLinks.length > 0 &&
                     <ul id={`subind_${mainindex}`} className="sub-menu">
                       {mainMenu.fields.SubMenuLinks.map((subMenu, subindex) => (
                          <li key={`sub_${subindex}`} className="menu-item">
                            {subMenu.fields.InternalLink && subMenu.fields.InternalLink.value.href != '' &&
                             (
                               subMenu.fields.InternalLink.value.href && subMenu.fields.InternalLink.value.href.startsWith("http") === true ?
                                 <Link field={subMenu.fields.InternalLink} className="helv_lt"/>
                                 :
                                 <NavLink to={subMenu.fields.InternalLink.value.href} className="helv_lt">
                                   <Text field={subMenu.fields.Title} />
                                 </NavLink>
                             )}
                           </li>
                       ))}
                     </ul>
                    }
                 </li>
                 )
                ))
             }
           </ul>
          </div>
          <div className="col-md-3 col-xs-12 rgt">
            <div className="rightMenuLinks">
              { MenuLinks &&    
                    MenuLinks.map((listItem, index) => <LinkListRendered key={index} index={index} item={listItem} />)
              }
              </div>
            <p className="link_social mt-0">
              { SocialLinks &&
              SocialLinks.map((listItem, index) => (
                  <React.Fragment key={index}>
                  {listItem.fields.InternalLink && listItem.fields.InternalLink.value.href.startsWith("http") === true ?
                    <a href={listItem.fields.InternalLink.value.href} target="_blank" key={index}>
                      <img src={listItem.fields.Image && listItem.fields.Image.value.src} className="img_custom_full"/>
                    </a>
                    :
                      <NavLink to={listItem.fields.InternalLink.value.href} target="_blank" key={index}>
                        <img src={listItem.fields.Image && listItem.fields.Image.value.src} className="img_custom_full"/>
                      </NavLink>
                  }
                  </React.Fragment>
              ))}
            </p>
            <p className="mt-0">
              <Text className="link_stext" field={this.props.fields.Text} tag="strong" />
            </p>
            <p className="link_contact">
              <i className="fas fa-phone-alt"></i>{i18next.t("ConsumerLabel")}<Text field={this.props.fields.TelephoneNumber} /><br />
              <a href={this.props.fields.EmailAddress ? "mailto:" + this.props.fields.EmailAddress.value : {}} target="_blank" className="helv_lt"><i className="far fa-envelope" aria-hidden="true"></i>{i18next.t("EmailLabel")} <Text field={this.props.fields.EmailAddress} /></a>
            </p>
          </div>                       
        </div>

        <div className="row_custom_small bottom-ft">
          <div className="col-md-12 pl-0">
            <p>
              <span className="helv_rom">{i18next.t("CopyrightLabel")}</span>
              {this.props.fields.CookiePolicyLink.value.href ?
                <Link field={this.props.fields.CookiePolicyLink} className={"helv_rom"}>{i18next.t("CookieConfigurationLabel")}</Link>
                :
                <span style={{cursor: "pointer"}} className={"helv_rom optanon-toggle-display"}>{i18next.t("CookieConfigurationLabel")}</span>
              }
            </p>
          </div>
        </div>
      </footer>


    );
  }
}

export default FooterMultilevel;
