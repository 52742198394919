import React from 'react';
import { NavLink } from "react-router-dom";
import Paging from "../../utils/paging";
import ReactComponent from "../../ReactComponent";
import Moment from "react-moment";
import {
  withSitecoreContext
} from "@sitecore-jss/sitecore-jss-react";

class PostsGrid extends ReactComponent {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      itemdata: [],
      after: 0,
      initialize: false,
      postTag: ''
    };
    this.onItemsChange = this.onItemsChange.bind(this);
  }

  onItemsChange(itemdata) {
    this.setState({itemdata: itemdata});
  }

  componentDidMount() {
    this.setState({
      postTag: window.location.href.substring(window.location.href.indexOf("=") + 1).toLowerCase().replace('#', '')
    });
  }

  render() {
    function SortByPostUpdate(x,y) {
      return ((x.item.date.value === y.item.date.value) ? 0 : ((x.item.date.value < y.item.date.value) ? 1 : -1 ));
    }

    if(this.state.postTag.includes('#')){
      this.state.postTag = this.state.postTag.replace('#','');
    }
    let QUERY = 'query={ search( index:"'+ this.indexName +'" first: ##first, after: "##after", fieldsEqual: [ { name: "postmaintag" value: "'+ this.state.postTag +'" }], rootItem: "/sitecore/content/'+ this.appName +'/") { ' +
        'results { totalCount pageInfo { startCursor endCursor hasNextPage hasPreviousPage } items { item { url date:field(name:"__Updated"){ value } ' +
        '...on PostRoute { postTitle { value } postShortDescription { value } postThumbnailImageMedium { src alt value } } } } } }}';

    const itemdata = this.state.itemdata;

    return(
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 main-wrap">
              <div className="main-content">
                <div className="posts" data-layout="grid">
                  <div className="col-3 posts-wrap posts-layout-grid row">
                    {itemdata.data &&
                      itemdata.data.search.results.items.sort(SortByPostUpdate).map((post, index) => (
                        <div className=" col-md-4 col-sm-6 hentry" key={index}>
                          <div className="hentry-wrap">
                            <div className="entry-featured">
                              <NavLink to={post.item.url}>
                                <img src={post.item.postThumbnailImageMedium.src}/>
                              </NavLink>
                            </div>
                            <div className="entry-info">
                              <div className="entry-header">
                                <h2 className="entry-title">
                                  <NavLink to={post.item.url}>{post.item.postTitle.value}</NavLink>
                                </h2>
                              </div>
                              <div className="entry-content">
                                {post.item.postShortDescription.value}
                              </div>
                              <div className="clearfix">
                                <div className="entry-meta icon-meta">
                                  <span className="meta-date">
                                    <i className="fa fa-calendar"></i>
                                    <span><Moment
                                        format="MMM DD, YYYY">{post.item.date.value}</Moment></span>
                                  </span>
                                </div>
                                <div className="readmore-link">
                                  <NavLink to={post.item.url}>Read More</NavLink>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    ))}
                  </div>
                  <Paging onItemsChange={this.onItemsChange} query={QUERY} itemsPerPage={this.props.fields.postPerPageGrid.value} />
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }

}

export default withSitecoreContext()(PostsGrid);
