import React from 'react';
import { Text,withSitecoreContext, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Formik } from "formik";
import { ApolloConsumer } from 'react-apollo';
import { loader as gqlLoader } from 'graphql.macro';
import './ratings.css';
import StarRatingV2 from '../StarRatingV2';
import RatingReviewsFilter from '../RatingReviewsFilter';
import ReactComponent from "../../ReactComponent";
import ProductReviews from "../../utils/ProductReviews/index.js";
import moment from 'moment';
import i18next from 'i18next';

const RATINGS_QUERY = gqlLoader('./RatingReviews.graphql');



class RatingReviews extends ReactComponent{

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            filteredData: null,
            error: '',
            show: true,
            status: "",
            rating: "",
            clickedStar: "",
            username: "",
            commentValue: ""
        }
        this.onStarClick = this.onStarClick.bind(this);
        this.userNameChange = this.userNameChange.bind(this);
        this.commentChange = this.commentChange.bind(this);
        this.rankLines = this.rankLines.bind(this);
    }
    componentDidMount(){
        this.getReviewsData()
        
    }

    componentDidUpdate(prevProps){
        if(prevProps.sitecoreContext.route.name !== this.props.sitecoreContext.route.name){
            this.getReviewsData()
        }
    }
    
    getReviewsData(){
        let productId = this.props.sitecoreContext.itemId.replace(/-/g, "").toLowerCase();
        let QUERY = 'query=query($product:String!){search( index:"'+ this.indexName +'" rootItem:"/sitecore/content/'+ this.appName + '/Content/Rating and Reviews",fieldsEqual:[{name:"_templatename",value:"RatingReviewItem"},{name:"product",value:$product},{name:"ratingstatus",value:"d109665e11e85a97a7d45337e3ba3106"}]) {results{items{item{id name...on RatingReviewItem{date: field(name: "__Created") {value} userName{value} rating{numberValue} ratingstatus{value targetItem{ name }} creationDate{value} comment{value} product{value} }}}}}}&variables={"product": "'+productId+'"}';
        fetch(this.getGraphqlEndpoint + QUERY)
        .then(res => res.json())
        .then(
            (result) => {

                //transform datetime into timestamp for filter compare
                result.data.search.results.items.map((itm) => {
                    itm.item.date.value = moment(itm.item.date.value).format('X')
                })

                this.setState({
                    data: result.data.search.results.items,
                    filteredData: result.data.search.results.items
                });
            
            },
            (error) => {
            this.setState({
                error: error
            });
            }
        )
    }


    onStarClick(event){
        let ratingValue =event.target.parentElement.getAttribute("data-rating")
        let clickedStarId = event.target.id;

        this.setState({
            rating: ratingValue,
            clickedStar: clickedStarId
        });
    }

    userNameChange(event){
        this.setState({username: event.target.value});
    }
    commentChange(event){
        this.setState({commentValue: event.target.value});
    }

    updateData = (data) =>{
        this.setState({filteredData: data})
    }

    rankLines() {

        let result = [];
        const data = this.state.data

        // add stars in element
        for(let i=0; i < 5; i++){
            const stars = []
            for(let j=0; j < i+1; j++){
                let star = <i key={j} className="fa fa-star"></i>
                stars.push(star)
        }
    
        // number of comments by rates
        const nbOfComments = data.filter(el => el.item.rating.numberValue == i+1).length
    
        // percentage for the bar
        const percentage = Math.floor((nbOfComments / data.length) * 100) + "%"
        
        // pushing lines
        result.push(<div key={i} className="line">
                        <div className="starsContainer">{stars}</div>
                        <div className="rankBarContainer"><div className="rankBar" style={{width: percentage}}></div></div>
                        <div className="advicesContainer">({nbOfComments} {i18next.t("Reviews")})</div>
                    </div>)
                            
        }
    
        return result.reverse()
    
    }
    
    render(){
        const productData = this.props.sitecoreContext.route.fields;

        return(
            <div className="container">
                <div className="col-md-12 margin_custom RandR">
                    <div className="row_custom_small">
                        <div className="separate"></div>
                        <h2 className="title-review decorated-title"><Text field={this.props.fields.RatingLabel} /></h2>
                        {this.state.data &&
                            <div className="reviewsSummary col-xs-12"> 
                                <div className="globalScore col-md-3">
                                    <h3>{i18next.t("GeneralRankingHeader")}</h3>  
                                    <div className="rank">{(Math.round(productData.rating.value*100)/100).toFixed(1)} <span>/ 5</span> </div>
                                    <StarRatingV2 value={parseInt(productData.rating.value)} />{" "}
                                    <ProductReviews productId={this.props.sitecoreContext.itemId} />
                                </div>
                                <div className="scoreRanking col-md-6">
                                    <h3>{i18next.t("NotesClassificationHeader")}</h3>
                                    <div className="linesContainer">
                                        {this.rankLines()}
                                    </div>
                                </div>
                                <div className="giveAdvice col-md-3">
                                <h3>{i18next.t("GiveOpinionHeader")}</h3>
                                    <p>{i18next.t("GiveOpinionText")}</p>
                                    <a className="btn" href="#review_form_wrapper">{i18next.t("GiveOpinionCta")}</a>
                                </div>
                            </div>
                        }
                        {this.state.data && <RatingReviewsFilter onFilterChange={this.updateData} comments={this.state.data}/>}
                        <div id="reviews" className="Reviews">
                            <div id="comments">
                                <h2 className="Reviews-title">Valoraciones</h2>
                                {this.state.filteredData && this.state.filteredData.length == 0 && <p className="noreviews another__font">{i18next.t("NoRatings")}</p> }
                                {this.state.filteredData && this.state.filteredData.length != 0 &&
                                    <ol className="commentlist">
                                        {this.state.filteredData && this.state.filteredData.map((commentItem,index)=>(
                                            <li className="review even thread-even depth-1" key={index} id="li-comment-36">
                                                 <div id="comment-36" className="comment_container">
                                                    <div className="comment-text">
                                                        <div className="meta">
                                                            <strong className="review__author"><StarRatingV2 value={commentItem.item.rating.numberValue} /><span>{commentItem.item.userName.value}</span> </strong>
                                                            <time className="review__published-date" >{commentItem.item.creationDate.value}</time>
                                                        </div>
                                                        
                                                        <div className="description">
                                                            <p>{commentItem.item.comment.value}</p>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </li>
                                        ))}
                                    </ol>
                                }
                            </div>
                            <div id="review_form_wrapper">
                                <div id="review_form">
                                    <div id="respond" className="comment-respond">
                                        <span id="reply-title" className="comment-reply-title same__font">
                                            <Text field={this.props.fields.RatingLabel} />
                                        </span>
                                        {this.state.show== false &&  this.state.status == "success" &&
                                            <div className="alert alert-success" role="alert">
                                                {i18next.t("TankYou")}
                                            </div>
                                        }
                                        {this.state.show && this.state.show== true &&
                                        <ApolloConsumer>
                                        {client => (
                                            <Formik
                                                enableReinitialize
                                                initialValues={{  userName: this.state.username,  rating: this.state.rating ,comment: this.state.commentValue, product: "",privacy: false }}
                                                onSubmit={async (values, { setErrors, setSubmitting }) => {
                                                const { data } = await client.query({
                                                    query: RATINGS_QUERY,
                                                    variables: {  userName: values.userName, rating: values.rating, comment: values.comment, product: this.props.sitecoreContext.itemId }
                                                });

                                                if (data.ratingreviews == "Review Submitted Successfully") {
                                                    this.setState({
                                                        show: false,
                                                        status: "success"
                                                    })
                                                }
                                                else {
                                                    setErrors({submit: "Error sending review."});
                                                    setSubmitting(false);
                                                }

                                                }}
                                                validate={values => {
                                                    let errors = {};
                                                    if (!values.privacy) {
                                                        errors.privacy = i18next.t("Required");
                                                    }
                                                    if (!values.comment) {
                                                        errors.comment = i18next.t("Required");
                                                    }


                                                    return errors;
                                                }}
                                            >
                                                {props => {
                                                const {
                                                    values,
                                                    touched,
                                                    errors,
                                                    handleChange,
                                                    handleSubmit
                                                } = props;


                                                return (
                                                    <>
                                                        <div className="pr-3">
                                                            <form id="commentform" className="comment-form another__font" onSubmit={handleSubmit}>
                                                                <p className="comment-notes">
                                                                   <Text field={this.props.fields.RequiredFieldsInfo} />
                                                                </p>
                                                                <div className="form-control-wrap">
                                                                    <input type="text" name="userName" id="userName" size="40" className="form-control" placeholder="Nombre completo" value={this.state.username} onChange={this.userNameChange} />
                                                                </div>
                                                                <div className="comment-form-rating same__font">
                                                                <label htmlFor="rating">{i18next.t("Rating")}</label>
                                                                    <p className="stars another__font">
                                                                        <span>
                                                                            <a className="star-1 same__font" data-rating="1" onClick={this.onStarClick}>
                                                                                <i className={"rating-1" ==this.state.clickedStar || 1 <= this.state.rating ? "fa fa-star": "far fa-star off"} id="rating-1" ></i>
                                                                            </a>
                                                                            <a className="star-2 same__font" data-rating="2" onClick={this.onStarClick}>
                                                                                <i className={"rating-2" ==this.state.clickedStar || 2 <= this.state.rating ? "fa fa-star": "far fa-star off"} id="rating-2"></i>
                                                                            </a>
                                                                            <a className="star-3 same__font" data-rating="3" onClick={this.onStarClick}>
                                                                                <i className={"rating-3" ==this.state.clickedStar || 3 <= this.state.rating ? "fa fa-star": "far fa-star off"} id="rating-3" ></i>
                                                                            </a>
                                                                            <a className="star-4 same__font" data-rating="4" onClick={this.onStarClick}>
                                                                                <i className={"rating-4" ==this.state.clickedStar || 4 <= this.state.rating ? "fa fa-star": "far fa-star off"} id="rating-4"></i>
                                                                            </a>
                                                                            <a className="star-5 same__font" data-rating="5" onClick={this.onStarClick}>
                                                                                <i className={"rating-5" ==this.state.clickedStar  ? "fa fa-star": "far fa-star off"} id="rating-5"></i>
                                                                            </a>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <p className="comment-form-comment another__font">
                                                                    <textarea id="comment" name="comment" cols="45" rows="8" placeholder="Comentario*" required="" value={this.state.commentValue} onChange={this.commentChange}></textarea>
                                                                    {errors.comment && touched.comment && (
                                                                        <div className="alert alert-danger" role="alert">
                                                                            {errors.comment}
                                                                        </div>

                                                                    )}
                                                                </p>
                                                                <div className="pprivacy same__font">
                                                                    <input type="checkbox" id="privacy" name="privacy" value="privacy-key" checked={values.privacy==true} className="privacyBox" onChange={handleChange} /><RichText field={this.props.fields.Privacy} /> 
                                                                    {errors.privacy && touched.privacy && (
                                                                        <div className="alert alert-danger" role="alert">{errors.privacy}</div>
                                                                    )}
                                                                </div>
                                                                <p>

                                                                </p>
                                                                <p className="form-submit another__font">
                                                                    <input name="submit" type="submit" id="submit" className="btn another__font" value={i18next.t("SendButton")} />
                                                                    <input type="hidden" name="comment_post_ID" value="983" id="comment_post_ID" />
                                                                    <input type="hidden" name="comment_parent" id="comment_parent" value="0" />
                                                                </p>
                                                            </form>
                                                        </div>
                                                    </>
                                                );
                                            }}
                                            </Formik>
                                        )}
                                        </ApolloConsumer>
                                        }

                                    </div>
                                    {/* <div className="row_custom_small Legal-form">
                                        <RichText field={this.props.fields.LegaInformation} />
                                        
                                    </div> */}
                                </div>
                                
                            </div>


                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withSitecoreContext()(RatingReviews);
