import React from 'react';
import { Text,Link } from '@sitecore-jss/sitecore-jss-react';
import {NavLink} from "react-router-dom";
const OverHead = (props) => {
  const {overHeadBannerContentList, designVariant} = props.fields;
  return (
      <div className={"OverHead row_custom_small tool-hd "+designVariant.value}>
          <div className="col-md-12">
                { overHeadBannerContentList &&    
                    overHeadBannerContentList.map((listItem, index) => (
                      <React.Fragment key={index}>
                      {listItem.fields.InternalLink && listItem.fields.InternalLink.value.href.startsWith("http") === true ?
                        <a href={listItem.fields.InternalLink.value.href} key={index} className="helv_rom"><i className={listItem.fields.IconClass.value} aria-hidden="true"></i><Text field={listItem.fields.Title} /></a>
                      :
                        <NavLink to={listItem.fields.InternalLink.value.href} key={index} className="helv_rom"><i className={listItem.fields.IconClass.value} aria-hidden="true"></i><Text field={listItem.fields.Title} /> </NavLink>
                      }
                      </React.Fragment>
                  ))}  
          </div>
      </div>
  );
}

export default OverHead;
