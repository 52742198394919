import React from 'react';
import { NavLink } from "react-router-dom";
import { Text, Image, Link } from '@sitecore-jss/sitecore-jss-react';

const BlockImageRight = (props) => (
    <div className={"BlockImageRight section parallax-background-2 "+props.fields.designVariant.value+""} style={{backgroundImage: `url(`+props.fields.BackgroundImage.value.src+`)`}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-6 col-md-6 pt-3 pb-5 colLeft">
            <Text data-wow-delay="0.3s" field={props.fields.Title} tag="h2" className="white mb-0 wow fadeInRight" />
            <Text data-wow-delay="0.4s" tag="p" className="white mt-3 mb-5 wow fadeInRight" field={props.fields.Description} />
            {props && props.fields && props.fields.Link && props.fields.Link.value && props.fields.Link.value.href.startsWith("http") === true ? 
              <Link field={props.fields.Link} className="btn btn-white-outline btn-align-left btn-icon btn-icon-slide-in btn-icon-right wow fadeInRight">
                <span>{props.fields.Link.value.title}</span>
                <i className="fa fa-long-arrow-right"></i>                
              </Link>
              :
              <NavLink to={props.fields.Link.value.href} className="btn btn-white-outline btn-align-left btn-icon btn-icon-slide-in btn-icon-right wow fadeInRight">
              <span>{props.fields.Link.value.title}</span>
              <i className="fa fa-long-arrow-right"></i>
              </NavLink>
            }             
          </div>
          <div className="col-sm-12 col-lg-6 col-md-6 hidden-sm hidden-xs wow fadeInLeft colRight">
            <div className="image">
              <img src={props.fields.Image && props.fields.Image.value.src} style={{maxHeight:'368px', maxWidth:'420px'}} />
            </div>
          </div>
        </div>
      </div>
    </div>
);

export default BlockImageRight;
